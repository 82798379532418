<template>
    <div class="league">
        <headComp></headComp>

        <div class="placePics">
            <!-- <img class="pic" src="../assets/imgs/place41.png"/>
            <img class="pic" src="../assets/imgs/place42.png"/> -->

            <img class="pic" src="../assets/imgs/invest1.png"/>
            <img class="pic fff" src="../assets/imgs/invest2.png"/>
        </div>
        <div class="formBox">
            <img src="../assets/imgs/invest3.png"/>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
export default {
    components: {
        headComp,footerComp
    },
}
</script>

<style lang="less" scoped>
.league{
    .placePics{
        img.pic{
            max-width:100%;
            &.fff{
                margin-top:-150px;
            }
        }
    }
    .formBox{
        &>img{
            max-width:100%;
        }
    }
}
</style>